<template>
  <v-app>
    <router-view />
    <!--     <template>
      <v-container class="mb-4">
        <v-row justify="end">
          <span style="color:#ccc">by xspand.iT <small class="ml-4 mr-4"> ver: {{ model.package_version }}</small></span>
        </v-row>
      </v-container>
    </template> -->
  </v-app>
</template>

<script>
  export default {
    name: 'LoginIndex',

    data: () => ({
      expandOnHover: false,
      model: {
        package_version: process.env.PACKAGE_VERSION || '0',
      },
    }),
  }
</script>
